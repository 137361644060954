.bio-container {
  margin-top: 30px;
  margin-left: 200px;
  margin-right: 100px;
  max-width: 60%;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}